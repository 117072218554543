import { request, resetCustomOnResponseError, setCustomOnResponseError } from './axios'
import { TCategoryClassification, TLogic } from '../type'
import { TPutCategoryRow, TPutCategoryClassification, TRowArchive, TCategoryRow, TCategory } from '../type/category-classification'
import { customErrorResponse } from '../utility/custom-error-response'

export const CategoryClassification = {
  listLogic: async (): Promise<TLogic[]> => {
    const { data } = await request({
      url: '/category-classification/logic/',
    })
    return data
  },

  addLogic: async (categoryClassification: TCategoryClassification) => {
    setCustomOnResponseError(customErrorResponse)
    return request({
      method: 'post',
      url: '/category-classification/logic/',
      data: categoryClassification,
    }).finally(() => {
      resetCustomOnResponseError()
    })
  },

  updateLogic: async (oldName: string, newName: string) => {
    setCustomOnResponseError(customErrorResponse)
    return request({
      method: 'put',
      url: '/category-classification/logic/',
      data: {
        old_name: oldName,
        new_name: newName,
      },
    }).finally(() => {
      resetCustomOnResponseError()
    })
  },

  archiveLogic: async (name: string): Promise<number> => {
    setCustomOnResponseError(customErrorResponse)
    return request({
      method: 'delete',
      url: '/category-classification/logic/?name=' + name,
    })
      .finally(() => {
        resetCustomOnResponseError()
      })
      .then((response) => response.status)
  },

  addCategory: async (categoryClassification: TCategoryClassification) => {
    setCustomOnResponseError(customErrorResponse)
    return request({
      method: 'post',
      url: '/category-classification/category/',
      data: categoryClassification,
    }).finally(() => {
      resetCustomOnResponseError()
    })
  },

  updateCategory: async (categoryClassification: TPutCategoryClassification) => {
    setCustomOnResponseError(customErrorResponse)
    return request({
      method: 'put',
      url: '/category-classification/category/',
      data: categoryClassification,
    }).finally(() => {
      resetCustomOnResponseError()
    })
  },

  archiveCategory: async (name: string) => {
    setCustomOnResponseError(customErrorResponse)
    return request({
      method: 'delete',
      url: '/category-classification/category/?name=' + name,
    }).finally(() => {
      resetCustomOnResponseError()
    })
  },

  listCategoryRows: async (categoryName: string): Promise<TCategory> => {
    const { data } = await request({
      url: `/category-classification/rows/${categoryName}/`,
    })
    return data
  },

  addCategoryRow: async (payload: TCategoryRow) => {
    setCustomOnResponseError(customErrorResponse)
    return request({
      method: 'post',
      url: '/category-classification/rows/',
      data: payload,
    }).finally(() => {
      resetCustomOnResponseError()
    })
  },

  updateCategoryRow: async (payload: TPutCategoryRow) => {
    setCustomOnResponseError(customErrorResponse)
    return request({
      method: 'put',
      url: '/category-classification/rows/',
      data: payload,
    }).finally(() => {
      resetCustomOnResponseError()
    })
  },

  archiveCategoryRow: async (row: TRowArchive) => {
    setCustomOnResponseError(customErrorResponse)
    return request({
      method: 'delete',
      url:
        '/category-classification/rows/?logic=' +
        row.logic +
        '&category=' +
        row.category +
        '&type=' +
        row.type +
        '&id=' +
        row.id +
        '&effective_start_date=' +
        row.effective_start_date,
    }).finally(() => {
      resetCustomOnResponseError()
    })
  },

  checkLogicAndTypes: async (logic: string, types: string[]): Promise<{ name: string; exists: boolean }[]> => {
    const { data } = await request({
      url: '/category-classification/rows/check/',
      params: {
        logic,
        types: types.join(','),
      },
    })

    return data
  },
}
