import {
  TPatientSourceRowToArchive,
  TPatientSourceRowToEdit,
  TSourceManagementEntityName,
  TSourceManagementVariables,
} from '../type/source-management'
import { customErrorResponse } from '../utility/custom-error-response'
import { request, resetCustomOnResponseError, setCustomOnResponseError } from './axios'

export const PatientSource = {
  getConfig: async (): Promise<TSourceManagementVariables> => {
    const {
      data: { patient_source_configs },
    } = await request({
      url: '/category-classification/patient-source/configuration/',
    })

    return {
      patient_source_configs,
    }
  },

  getVariableDetails: async (sourceName: string, variableName: string, search_key?: string, includeTerminated = false): Promise<any> => {
    const searchParam = search_key ? `&search=${search_key}` : ''

    const {
      data: { patient_source_variable_details },
    } = await request({
      url: `/category-classification/patient-source/management/variable/?include_terminated=${includeTerminated}&patient_source_variable_name=${variableName}&patient_source_name=${sourceName}${searchParam}`,
    })
    return {
      patient_source_variable_details,
    }
  },

  getNameById: async (variable: string, entity_id: string): Promise<TSourceManagementEntityName> => {
    const {
      data: { patient_source_entity_name },
    } = await request({
      url: `/category-classification/patient-source/management/entity-meta/?patient_source_variable_name=${variable}&patient_source_entity_id=${entity_id}`,
    })
    return {
      patient_source_entity_name,
    }
  },

  updatePatientSource: async (payloadToUpdate: any) => {
    setCustomOnResponseError(customErrorResponse)
    return request({
      method: 'patch',
      url: '/category-classification/patient-source/management/',
      data: {
        ...payloadToUpdate,
      },
    }).finally(() => {
      resetCustomOnResponseError()
    })
  },

  createPatientSource: async (payloadToCreate: any) => {
    setCustomOnResponseError(customErrorResponse)
    return request({
      method: 'post',
      url: '/category-classification/patient-source/management/',
      data: {
        ...payloadToCreate,
      },
    }).finally(() => {
      resetCustomOnResponseError()
    })
  },

  updateRow: async (row: TPatientSourceRowToEdit) => {
    setCustomOnResponseError(customErrorResponse)
    return request({
      method: 'patch',
      url: '/category-classification/patient-source/management/row/',
      data: {
        ...row,
      },
    }).finally(() => {
      resetCustomOnResponseError()
    })
  },
  removeRow: async (payloadToRemove: TPatientSourceRowToArchive) => {
    setCustomOnResponseError(customErrorResponse)
    const { start_date, patient_source_entity_id, patient_source_variable_name, patient_source_name } = payloadToRemove

    return request({
      method: 'delete',
      url: `/category-classification/patient-source/management/row/?patient_source_name=${patient_source_name}&patient_source_variable_name=${patient_source_variable_name}&patient_source_entity_id=${patient_source_entity_id}&start_date=${start_date}`,
    }).finally(() => {
      resetCustomOnResponseError()
    })
  },
  removeVariable: async (variable: string, source_name: string) => {
    setCustomOnResponseError(customErrorResponse)
    return request({
      method: 'delete',
      url: `/category-classification/patient-source/management/variable/?patient_source_name=${source_name}&patient_source_variable_name=${variable}`,
    }).finally(() => {
      resetCustomOnResponseError()
    })
  },
}
