import React, { useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { PATH } from '../../utility/path'
import { TEXT } from '../../utility/text'
import './sidebar.scss'
import { Icon } from '../icon'
import { PermissionKey, PermissionProvider } from '../../context'

export const Sidebar = () => {
  const { pathname } = useLocation()
  const [isOpenFeeCalculation, setIsOpenFeeCalculation] = useState(true)
  const [isOpenDataInput, setIsDataInput] = useState(true)
  const [isOpenAdmin, setIsOpenAdmin] = useState(true)
  const [isOpenADManagement, setIsOpenADManagement] = useState(true)
  const [isOpenPatientSource, setIsOpenPatientSource] = useState(true)

  return (
    <aside className='f f-d-c bg-base100'>
      <nav className='nav f f-d-c p-y-sm'>
        <PermissionProvider permission={PermissionKey.mn_home}>
          <NavLink
            className='nav__unit nav__unit--level-1 t-s-md t-w-500'
            end
            to={PATH.home}
            data-test='menu-home'
          >
            <Icon name='Home' />
            {TEXT.menu.home}
          </NavLink>
        </PermissionProvider>
        <PermissionProvider permission={PermissionKey.mn_upload}>
          <NavLink
            className='nav__unit nav__unit--level-1 t-s-md t-w-500'
            to={PATH.upload.upload}
            data-test='menu-upload'
          >
            <Icon name='Upload' />
            {TEXT.menu.upload}
          </NavLink>
        </PermissionProvider>
        <PermissionProvider permission={PermissionKey.mn_data_input}>
          <div
            className='nav__unit nav__trigger nav__unit--level-1 t-s-md t-w-500'
            onClick={() => setIsDataInput(!isOpenDataInput)}
            data-test='menu-data-input'
          >
            <Icon name='Database' />
            {TEXT.menu.data_input}
          </div>
        </PermissionProvider>
        <PermissionProvider permission={PermissionKey.mn_patient_source}>
          <div
            className='nav__unit nav__trigger nav__unit--level-1 t-s-md t-w-500'
            onClick={() => setIsOpenPatientSource(!isOpenPatientSource)}
            data-test='menu-patient-source'
          >
            <Icon
              name='UserCheck'
              fill='none'
              stroke='currentColor'
              strokeWidth='2'
            />
            {TEXT.menu.patient_source}
            <Icon
              name={isOpenPatientSource ? 'ArrowBottom' : 'ArrowRight'}
              className='m-r-md m-l-auto'
            />
          </div>
          <div className={`nav__content ${isOpenPatientSource ? 'is-active' : ''}`}>
            <PermissionProvider permission={PermissionKey.mn_source_mngt}>
              <NavLink
                className='nav__unit nav__unit--level-3 t-s-md t-w-500'
                to={PATH.patient_source.source_management}
                data-test='menu-source_management'
              >
                {TEXT.menu.source_management}
              </NavLink>
            </PermissionProvider>
          </div>
        </PermissionProvider>
        <PermissionProvider permission={PermissionKey.mn_fee_calc}>
          <div
            className='nav__unit nav__trigger nav__unit--level-1 t-s-md t-w-500'
            onClick={() => setIsOpenFeeCalculation(!isOpenFeeCalculation)}
            data-test='menu-fee-calculation'
          >
            <Icon name='Range' />
            {TEXT.menu.fee_calculation}
            <Icon
              name={isOpenFeeCalculation ? 'ArrowBottom' : 'ArrowRight'}
              className='m-r-md m-l-auto'
            />
          </div>
          <div className={`nav__content ${isOpenFeeCalculation ? 'is-active' : ''}`}>
            <PermissionProvider permission={PermissionKey.mn_field_conf}>
              <NavLink
                className='nav__unit nav__unit--level-3 t-s-md t-w-500'
                to={PATH.fee_calculation.field_configuration.flags}
                data-test='menu-field-configuration'
              >
                {TEXT.menu.field_configuration}
              </NavLink>
            </PermissionProvider>
            <PermissionProvider permission={PermissionKey.mn_fee_mngt}>
              <NavLink
                className='nav__unit nav__unit--level-3 t-s-md t-w-500'
                to={PATH.fee_calculation.fee_management.fees}
                data-test='menu-fee-management'
              >
                {TEXT.menu.fee_management}
              </NavLink>
            </PermissionProvider>
          </div>
        </PermissionProvider>
        <PermissionProvider permission={PermissionKey.mn_support}>
          <NavLink
            className='nav__unit nav__unit--level-1 t-s-md t-w-500'
            to={PATH.support}
            data-test='menu-support'
          >
            <Icon name='Support' />
            {TEXT.menu.support}
          </NavLink>
        </PermissionProvider>
        <PermissionProvider permission={PermissionKey.mn_admin}>
          <div
            className='nav__unit nav__trigger nav__unit--level-1 t-s-md t-w-500'
            onClick={() => setIsOpenAdmin(!isOpenAdmin)}
            data-test='menu-admin'
          >
            <Icon name='User' />
            {TEXT.menu.admin}
            <Icon
              name={isOpenAdmin ? 'ArrowBottom' : 'ArrowRight'}
              className='m-r-md m-l-auto'
            />
          </div>
          <div className={`nav__content ${isOpenAdmin ? 'is-active' : ''}`}>
            <PermissionProvider permission={PermissionKey.mn_sync}>
              <NavLink
                className='nav__unit nav__unit--level-3 t-s-md t-w-500'
                to={PATH.admin.synchronization}
                data-test='menu-synchronization'
              >
                {TEXT.menu.synchronization}
              </NavLink>
            </PermissionProvider>
            <PermissionProvider permission={PermissionKey.mn_user_roles_mngt}>
              <NavLink
                className='nav__unit nav__unit--level-3 t-s-md t-w-500'
                to={PATH.admin.user_roles_management.permissions}
                data-test='menu-user-role-management'
              >
                {TEXT.menu.user_roles_management}
              </NavLink>
            </PermissionProvider>
            <PermissionProvider permission={PermissionKey.mn_aux_rules_mngt}>
              <NavLink
                className='nav__unit nav__unit--level-3 t-s-md t-w-500'
                to={PATH.admin.aux_rules_management.validation_rules}
                data-test='menu-aux-rules-management'
              >
                {TEXT.menu.aux_rules_management}
              </NavLink>
            </PermissionProvider>
            <PermissionProvider permission={PermissionKey.mn_category_classification}>
              <NavLink
                className='nav__unit nav__unit--level-3 t-s-md t-w-500'
                to={PATH.admin.category_classifications}
                data-test='menu-category-classifications'
              >
                {TEXT.menu.category_classification}
              </NavLink>
            </PermissionProvider>
            <PermissionProvider permission={PermissionKey.mn_ad_mngt}>
              <div
                className='nav__unit nav__trigger nav__unit--level-3 t-s-md t-w-500'
                onClick={() => setIsOpenADManagement(!isOpenADManagement)}
                data-test='menu-ad_management'
              >
                {TEXT.menu.ad_management}
                <Icon
                  name={isOpenADManagement ? 'ArrowBottom' : 'ArrowRight'}
                  className='m-r-md m-l-auto'
                />
              </div>
              <div className={`nav__content ${isOpenADManagement ? 'is-active' : ''}`}>
                <PermissionProvider permission={PermissionKey.mn_users}>
                  <NavLink
                    className={`nav__unit nav__unit--level-4 t-s-md t-w-500${
                      pathname.match(PATH.admin.ad_management.user) ? ' active' : ''
                    }`}
                    to={PATH.admin.ad_management.users}
                    data-test='menu-users'
                  >
                    {TEXT.menu.users}
                  </NavLink>
                </PermissionProvider>
                <PermissionProvider permission={PermissionKey.mn_ce}>
                  <NavLink
                    className={`nav__unit nav__unit--level-4 t-s-md t-w-500${
                      pathname.match(PATH.admin.ad_management.covered_entity) ? ' active' : ''
                    }`}
                    to={PATH.admin.ad_management.covered_entities}
                    data-test='menu-covered-entities'
                  >
                    {TEXT.menu.covered_entities}
                  </NavLink>
                </PermissionProvider>
                <PermissionProvider permission={PermissionKey.mn_memberships}>
                  <NavLink
                    className={`nav__unit nav__unit--level-4 t-s-md t-w-500${
                      pathname.match(PATH.admin.ad_management.membership) ? ' active' : ''
                    }`}
                    to={PATH.admin.ad_management.memberships}
                    data-test='menu-memberships'
                  >
                    {TEXT.menu.memberships}
                  </NavLink>
                </PermissionProvider>
              </div>
            </PermissionProvider>
          </div>
        </PermissionProvider>
        <PermissionProvider permission={PermissionKey.mn_pharmasee}>
          <NavLink
            className='nav__unit nav__unit--level-1 t-s-md t-w-500'
            to={PATH.pharmasee}
            data-test='menu-pharmasee'
          >
            <Icon name='Pharmasee' />
            {TEXT.menu.pharmasee}
          </NavLink>
        </PermissionProvider>
      </nav>
      <footer>
        <div className='t-s-xs t-c-base700 t-w-600'>© Copyright {new Date().getFullYear()} PharmalytIQ, LLC. All Rights Reserved.</div>
      </footer>
    </aside>
  )
}
