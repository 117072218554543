import { AxiosError } from 'axios'
import { appFetch } from '../store/reducer/app'
import { store } from '../store'
import { toast } from 'react-toastify'

export const customErrorResponse = async (error: AxiosError): Promise<any> => {
  const { response }: AxiosError = { ...error }

  if (response && response.data) {
    const errorMessage = (response.data as { 'Error Message'?: { details: string; error_code: string } })['Error Message']
    if (errorMessage) {
      const { details, error_code } = errorMessage
      const { status } = response

      if (status === 401 || status === 403) {
        if (error_code === 'ER-401-2') {
          await store.dispatch(appFetch())
        }
      } else if (status === 422 && error_code === 'ER-422-3') {
        let errorMessages = []

        if (typeof details === 'string') {
          errorMessages.push(details)
        } else {
          errorMessages = [...Object.values(details['json'] || {}).flat(), ...Object.values(details['query'] || {}).flat()]
        }
        if (errorMessages.length > 0) {
          toast(errorMessages[0] as string, {
            type: 'error',
            position: 'top-center',
          })
        }
      } else {
        toast(JSON.stringify(details), {
          type: 'error',
          position: 'top-center',
        })
      }
    }
  }
  console.error({ ...error })
  return Promise.reject({ ...error })
}
